export interface Token {
    access_token?: string;
    refresh_token?: string;
}

export interface User {
    id?: number;
    firstName?: string;
    lastName?: string;
    email: string;
    password?: string;
}

export interface SupplyItem {
    id?: number;
    name: string;
    description?: string;
    place?: string;
    amount?: number;
    stock?: number;
    note?: string;
    state?: boolean;
    notice_id?: number;
    last_update?: Date;
}

export interface Note {
    id?: number;
    name: string;
    description?: string;
    state?: boolean;
    supplies?: SupplyItem[];
}

export function createInitialUser(): User {
  return {
    firstName: '',
    lastName: '',
    email: ''
  };
}

export function createInitialNote(): Note {
  return {
    name: '',
    description: '',
    supplies: [],
  };
}

export function createInitialSupplyItem(): SupplyItem {
  return {
    name: '',
    description: '',
    place: '',
    note: '',
    stock: 0
  };
}
