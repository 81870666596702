import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { LocalService } from '../../_services/local.service';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-footbar',
  templateUrl: './footbar.component.html',
  styleUrls: ['./footbar.component.scss']
})
export class FootbarComponent implements OnInit {

  currentURL: string = '';
  currentItem: number = 0;
  loggedIn$: boolean = false;


  constructor(
    private router: Router,
    private auth: AuthenticationService,
    private localSrv: LocalService) {
    }

  ngOnInit() {
    // Check if user is logged in
    this.auth.isLoggedIn().subscribe(
      data => {
        this.loggedIn$ = data;
      }
    );
    this.getItem();
    this.getPath();
  }

  getItem() {
    this.localSrv.currentItem
    .subscribe(
      id => this.currentItem = id,
      error => this.currentItem = 0
    );
  }

  getPath() {
    this.router.events
    .pipe(
      filter((event: Event) => event instanceof NavigationEnd))
    .subscribe((event: any) => {
      // gets current url
      this.currentURL = event.url;
    });
  }
}
