import { Injectable, Injector } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from '../_services/authentication.service';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {

    refreshInProgress = false;

    constructor(
        private authenticationService: AuthenticationService,
        private injector: Injector) { }

    intercept(
        request: HttpRequest<any>,
        next: HttpHandler
    ): Observable<HttpEvent<any>> {

       const refresh_token = this.authenticationService.getCurrentAccessToken('refresh');
       const access_token = this.authenticationService.getCurrentAccessToken('access');

        // if token is expired, delete access token
        if (this.authenticationService.isExpired()) {
            console.log('Remove access_token because he is exipred');
            this.authenticationService.removeAccessToken();
        }

        // if token is expired, delete access token
        if (this.authenticationService.isExpired('refresh_token')) {
            console.log('Complete logout because refresh_token is exipred');
            this.authenticationService.logout();
        }

        // if refresh token available, try to get new access_token
        if (refresh_token && !access_token && !this.refreshInProgress) {

            console.log('Turn on token refresh');
            this.refreshInProgress = true;

            this.authenticationService.refreshToken()
            .subscribe(
              data => {
                this.refreshInProgress = false;
                console.log('Returned AccessToken:', data.access_token);
              },
              error => console.log('Error', error)
            );
        }

        // add authorization header with jwt token if available
        console.log('Interceptor:', access_token);
        if (access_token) {
            request = request.clone({
                setHeaders: {
                    'X-API-KEY': `${access_token}`
                }
            });
        }

        console.log('Request:', request.url);
        return next.handle(request);
    }
}
