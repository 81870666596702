import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { SuppliesService } from '../../../_services/supplies.service';
import { SupplyItem, createInitialSupplyItem, Note } from '../../../_models/model-interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotesService } from '../../../_services/notes.service';


@Component({
  selector: 'app-supplyitem-edit',
  templateUrl: './supplyitem-edit.component.html',
  styleUrls: ['./supplyitem-edit.component.scss']
})
export class SupplyitemEditComponent implements OnInit {

  supply$: SupplyItem = createInitialSupplyItem();
  notes$: Note[];
  note: Note;
  supplyItemForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private supplyService: SuppliesService,
    private noteService: NotesService,
    private router: Router) {
      this.supplyItemForm = fb.group({
        name: ['', Validators.required],
        description: [''],
        place: [''],
        note: [''],
        amount: [''],
        stock: ['', Validators.pattern('[0-9]*')],
        notice_id: [''],
        state: ['']
      });
    }

  get f() { return this.supplyItemForm.controls; }

  ngOnInit() {
    this.fetchNotes();
    this.fetchItem();
    console.log(this.supplyItemForm);
  }

  fetchItem() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.supplyService.getSupplyItem(id)
      .subscribe(
        data => {
          this.supply$ = data;
          console.log('Fetched supplyitem ', this.supply$);
          this.setFormValues();
        },
        error => {
          console.log('Error', error);
        }
    );
  }
  
  fetchNotes() {
    this.noteService.getNotes()
      .subscribe(
        data => {
          this.notes$ = data;
          console.log('Zettel erfolgreich empfangen', this.notes$);
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  /*
  getNote(): Note {
    return this.notes$.find(note => note.id == this.supply$.notice_id);
  }
  */

  // Set values into form from fetched supplyitem
  setFormValues() {
    Object.keys(this.supplyItemForm.controls)
    .forEach(
      key => {
        console.log('Key', key, 'Value', this.supplyItemForm.controls[key]);
        this.supplyItemForm.controls[key].setValue(this.supply$[key]);
      }
    );
  }

  tryUpdate() {
    this.submitted = true;

    // stop here if form is invalid
    if (this.supplyItemForm.invalid) {
      return;
    }

    const res = this.supplyItemForm.value;
    this.supply$.name = res.name;
    this.supply$.description = res.description;
    this.supply$.note = res.note;
    this.supply$.notice_id = Number(res.notice_id);
    this.supply$.stock = res.stock;
    this.supply$.place = res.place;
    
    this.supplyService.updateSupplyItem(this.supply$.id, this.supply$)
      .subscribe(
        data => {
          console.log('SupplyItem erfolgreich gespeichert', this.supply$);
          this.goBack();
        },
        error => {
          console.log('Error', error);
        }
      );

    /*
    this.loading = true;
    this.userService.register(this.registerForm.value)
        .pipe(first())
        .subscribe(
            data => {
                this.alertService.success('Registration successful', true);
                this.router.navigate(['/login']);
            },
            error => {
                this.alertService.error(error);
                this.loading = false;
            });
            */
  }

  cleanObject(obj: SupplyItem) {
    for ( const key in obj ) {
      if ( obj[key] === null || obj[key] === undefined ) {
        delete obj[key];
      }
    }
    return obj;
  }

  update(formData) {
    console.log('Form: ',formData, 'Object: ', this.supply$);
    let newItem: SupplyItem = createInitialSupplyItem();
    newItem = formData;
    newItem.stock = Number(newItem.stock);
    console.log('NewItem', newItem);
    formData = this.cleanObject(formData);

    Object.keys(formData)
    .forEach(
      key => {
        if (formData[key] !== "" || formData[key] !== null) {
          if (key === 'stock') {
            this.supply$[key] = Number(formData[key]);
          } else {
            this.supply$[key] = formData[key];
          }
          
        }
      });
    //this.supply$ = formData;
    this.supply$.stock = Number(this.supply$.stock);
    console.log(formData);
    this.supplyService.updateSupplyItem(this.supply$.id, formData)
      .subscribe(
        data => {
          console.log('SupplyItem erfolgreich gespeichert', formData);
          this.supply$ = formData;
          this.goBack();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  delete() {
    this.supplyService.deleteSupplyItem(this.supply$.id)
      .subscribe(
        supplyService => {
          console.log('SuppplyItem erfolgreich gelöscht');
          this.goBack();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  goBack(): void {
    this.router.navigate(['/supplies']);
  }
}
