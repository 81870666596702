import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../_services';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {

  loggedIn$: boolean = false;

  constructor(
    private auth: AuthenticationService
  ) { }

  ngOnInit() {
    // Check if user is logged in
    this.auth.isLoggedIn().subscribe(
      data => {
        this.loggedIn$ = data;
      }
    );
  }

  logout() {
    this.auth.logout();
  }
}
