import { Component, OnInit } from '@angular/core';
import { SuppliesService } from '../../../_services/supplies.service';
import { SupplyItem } from '../../../_models/supplyitem';
import { Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';

@Component({
  selector: 'app-supplyitem-create',
  templateUrl: './supplyitem-create.component.html',
  styleUrls: ['./supplyitem-create.component.scss']
})
export class SupplyitemCreateComponent implements OnInit {

  supplyItemForm: FormGroup;
  supply$: SupplyItem;
  // name: string;
  // description: string;
  // place: string;

  constructor(
    private fb: FormBuilder,
    private supplyService: SuppliesService,
    private router: Router) {
      this.supplyItemForm = fb.group({
        name: ['', Validators.required],
        description: [''],
        place: [''],
        stock: ['', Validators.min(0)]
      })
    }

  ngOnInit() {
  }

  create(formData: any) {
    console.log(formData);
    this.supplyService.createSupplyItem(formData)
      .subscribe(
        data => {
          console.log('Supplyitem erfolgreich gespeichert', formData);
          this.supply$ = formData;
          this.goBack();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  goBack(): void {
    this.router.navigate(['/supplies']);
  }
}
