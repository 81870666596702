import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'settings-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit {

  menu_items = [
    {
      url : '/settings/users',
      name : 'Benutzer'
    },
    {
      url: '/settings/groups',
      name: 'Gruppen'
    }
  ]

  constructor() { }

  ngOnInit() {
  }

}
