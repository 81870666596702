import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {
  title = 'sam2';
  submenu = false;

  content = "default"; // can be default, settings
  currentURL: string = '';
  
  constructor (private router: Router) {}

  ngOnInit() {
    this.getPath();
    console.log(this.currentURL);
  }

  getPath() {
    this.router.events
    .pipe(
      filter((event: Event) => event instanceof NavigationEnd))
    .subscribe((event: any) => {
      // gets current url
      this.currentURL = event.url;
    });
  }
}
