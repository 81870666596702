import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotesService } from '../../../_services/notes.service';
import { Note, createInitialNote } from '../../../_models/model-interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';


@Component({
  selector: 'app-note-edit',
  templateUrl: './note-edit.component.html',
  styleUrls: ['./note-edit.component.scss']
})
export class NoteEditComponent implements OnInit {

  note$: Note = createInitialNote();
  name: string;
  description: string;

  noteForm: FormGroup;

  constructor(
    private fb: FormBuilder,
    private notesService: NotesService,
    private router: Router,
    private route: ActivatedRoute) {
      this.noteForm = fb.group({
        name: ['', Validators.required],
        description: ['']
      })
    }

  get f() { return this.noteForm.controls; }    

  ngOnInit() {
    this.fetchNote();
  }

  fetchNote(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.notesService.getNote(id)
      .subscribe(
        data => {
          this.note$ = data;
          console.log('Successfully fetched note', this.note$);
          this.setFormValues();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  setFormValues() {
    Object.keys(this.noteForm.controls)
    .forEach(
      key => {
        console.log('Key', key, 'Value', this.noteForm.controls[key]);
        this.noteForm.controls[key].setValue(this.note$[key]);
      }
    )
  }  

  update() {
    const res = this.noteForm.value;
    Object.keys(res).forEach(key => {
      // console.log('Key', key);
      this.note$[key] = res[key];
    });

    let payload = this.note$;
    delete payload.supplies;
    this.notesService.updateNote(this.note$.id, payload)
    .subscribe(
      data => {
        console.log('Notiz erfolgreich gespeichert', payload);
        this.goBack();
        // this.ngOnInit();
      }
    );

    /*
    console.log('Form: ' + formData, 'Object: ' + this.note$);
    this.notesService.updateNote(this.note$.id, formData)
      .subscribe(
        data => {
          console.log('Notiz erfolgreich gespeichert', formData);
          this.note$ = formData;
          this.goBack();
          // this.ngOnInit();
        },
        error => {
          console.log('Error', error);
        }
      );
    */
  }

  delete(): void {
    this.notesService.deleteNote(this.note$.id)
      .subscribe(
        data => {
          console.log('Notiz erfolgreich entfernt', this.note$);
          this.goBack();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  goBack() {
    this.router.navigate(['/notes']);
  }

}
