import { Component, OnInit } from '@angular/core';
import { NotesService } from '../../../_services/notes.service';
import { Note, createInitialNote } from '../../../_models/model-interfaces';
import { Router } from '@angular/router';

@Component({
  selector: 'app-note-create',
  templateUrl: './note-create.component.html',
  styleUrls: ['./note-create.component.scss']
})

export class NoteCreateComponent implements OnInit {

  note$: Note = createInitialNote();

  constructor(
    private data: NotesService,
    private router: Router) {  }

  ngOnInit() {
  }

  create(formData: Note) {
    this.data.createNote(formData)
      .subscribe(
        data => {
          console.log('Notiz erfolgreich gespeichert', formData);
          this.goBack();
          this.note$ = formData;
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  goBack() {
    this.router.navigate(['/notes']);
  }
}
