import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard } from './_guards/auth.guard';
import { AuthenticationService, AlertService, UserService } from './_services';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';

import { NavbarComponent } from './navigation/navbar/navbar.component';
import { FootbarComponent } from './navigation/footbar/footbar.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NotesComponent } from './shopping/notes/notes.component';
import { NotesService } from './_services/notes.service';
import { SuppliesComponent } from './shopping/supplies/supplies.component';
import { SuppliesService } from './_services/supplies.service';
import { NoteComponent } from './shopping/notes/note/note.component';
import { NoteCreateComponent } from './shopping/notes/note-create/note-create.component';
import { NoteEditComponent } from './shopping/notes/note-edit/note-edit.component';
import { SupplyitemComponent } from './shopping/supplies/supplyitem/supplyitem.component';
import { SupplyitemCreateComponent } from './shopping/supplies/supplyitem-create/supplyitem-create.component';
import { SupplyitemEditComponent } from './shopping/supplies/supplyitem-edit/supplyitem-edit.component';
import { CreateComponent } from './forms/supplies/create/create.component';
import { SettingsModule } from './settings/settings.module';
import { MenuComponent } from './settings/menu/menu.component';


@NgModule({
  declarations: [
    AppComponent,
    NavbarComponent,
    FootbarComponent,
    RegisterComponent,
    LoginComponent,
    DashboardComponent,
    NotesComponent,
    NoteComponent,
    NoteCreateComponent,
    NoteEditComponent,
    SuppliesComponent,
    SupplyitemComponent,
    SupplyitemCreateComponent,
    SupplyitemEditComponent,
    CreateComponent,
    MenuComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule
  ],
  providers: [
    AuthGuard,
    AuthenticationService,
    AlertService,
    NotesService,
    SuppliesService,
    UserService,
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true }
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
