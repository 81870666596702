import { Component, OnInit } from '@angular/core';
import { SupplyItem, Note, createInitialSupplyItem } from '../../../_models/model-interfaces';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NotesService } from '../../../_services/notes.service';
import { SuppliesService } from '../../../_services/supplies.service';

@Component({
  selector: 'app-form-supplies-create',
  templateUrl: './create.component.html',
  styleUrls: ['./create.component.scss']
})
export class CreateComponent implements OnInit {

  // Item / Form values
  notes$: Note[];
  item: SupplyItem = createInitialSupplyItem();

  // Form parameters
  supplyItemForm: FormGroup;
  submitted = false;
  loading = false;

  constructor(
    private fb: FormBuilder,
    private noteSrv: NotesService,
    private supplySrv: SuppliesService
  ) {
    this.supplyItemForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      place: [''],
      note: [''],
      amount: [0],
      stock: [0, Validators.pattern('[0-9]*')],
      notice_id: [''],
      state: ['']
    });
   }

  ngOnInit() {
    this.fetchNotes();
  }

  get f() { return this.supplyItemForm.controls; }

  fetchNotes() {
    this.noteSrv.getNotes()
      .subscribe(
        data => {
          this.notes$ = data;
          console.log('Zettel erfolgreich empfangen', this.notes$);
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  
  create() {

    this.submitted = true;

    // stop here if form is invalid
    if (this.supplyItemForm.invalid) {
      return;
    }

    this.loading = true;

    const res = this.supplyItemForm.value;
    Object.keys(res).forEach(key => {
      this.item[key] = res[key];
    });
    this.supplySrv.createSupplyItem(this.item)
    .subscribe(
      data => {
        console.log('Success', this.supplySrv.serialize(this.item));
        this.submitted = false;
        this.loading = false;
        this.clearForm();
        this.ngOnInit();
      },
      error => console.log('Error, error')
    );
  }


  clearForm() {
    this.supplyItemForm = this.fb.group({
      name: ['', Validators.required],
      description: [''],
      place: [''],
      note: [''],
      amount: [0],
      stock: [0, Validators.pattern('[0-9]*')],
      notice_id: [''],
      state: [0]
    });
  }

}
