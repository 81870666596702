import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../_services';
import { Observable, interval } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

  currentTime$: Observable<Date>;

  constructor(
    private authService: AuthenticationService) { }

  ngOnInit() {



    this.currentTime$ = interval(1000)
    .pipe(
      map(
        x => new Date()
    ));
  }
}
