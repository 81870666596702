import { Component, OnInit } from '@angular/core';
import { NotesService } from '../../_services/notes.service';
import { Note } from '../../_models/model-interfaces';
import { Router } from '@angular/router';


@Component({
  selector: 'app-notes',
  templateUrl: './notes.component.html',
  styleUrls: ['./notes.component.scss']
})
export class NotesComponent implements OnInit {

  notes$: Note[];

  constructor(
    private noteService: NotesService,
    private router: Router) { }

  ngOnInit() {
    this.fetchNotes();
  }

  fetchNotes(): void {
    this.noteService.getNotes()
      .subscribe(
        data => {
          console.log('Zettel erfolgreich empfangen', this.notes$);
          this.notes$ = data;
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  delete(event): void {
    /*
    var target = event.target || event.srcElement || event.currentTarget;
    var nameAttr = target.attributes.id;
    // var value = nameAttr.nodeValue;
    console.log(event.target.attributes.name);
    */
    const id = event.target.name;
    console.log(id);

    this.noteService.deleteNote(id)
      .subscribe(
        data => {
          console.log('Notiz erfolgreich entfernt', id);
          this.ngOnInit();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  goBack(): void {
    this.router.navigate(['/notes']);
  }
}
