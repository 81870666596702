import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private SCHEME = 'https';
  // private HOSTNAME = '172.30.120.75';
  // private HOSTNAME = '172.18.100.216';
  // private HOSTNAME = '10.0.0.250';
  private HOSTNAME = 'samapi.dudecamp.org';
  private PORT = '';
  private PATH = '/api';
  private API_SOURCE = new BehaviorSubject(this.SCHEME + '://' + this.HOSTNAME + this.PORT + this.PATH);
  API_URL = this.API_SOURCE.asObservable();


  constructor() { }


  getAPI_URL() {
    return this.API_URL;
  }
}
