import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { User } from '../_models/model-interfaces';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class UserService {

  private BASE_URL;

  constructor(
    private http: HttpClient,
    private conf: ConfigService) { 
      this.conf.getAPI_URL().subscribe(
        data => this.BASE_URL = data
      );
    }

  getAll(): Observable<User[]> {
    return this.http.get<User[]>(this.BASE_URL + '/buy/users/');
  }

  getById(id: number) {
    return this.http.get<User>(this.BASE_URL + '/buy/users/' + id);
  }

  register(user: User) {
    return this.http.post<User>(this.BASE_URL + '/buy/users/', user);
  }

  update(user: User) {
    return this.http.put<User>(this.BASE_URL + '/buy/users/' + user.id, user);
  }

  delete(id: number) {
    return this.http.delete<User>(this.BASE_URL + '/buy/users/' + id);
  }
}
