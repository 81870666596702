import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { SupplyItem } from '../_models/model-interfaces';
import { ConfigService } from './config.service';
import { URLSearchParams } from '@angular/http';
import { catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class SuppliesService {

  private BASE_URL;
  supplies$: Observable<SupplyItem[]>;

  constructor(private http: HttpClient,
    private conf: ConfigService) {
      this.conf.getAPI_URL().subscribe(
        data => this.BASE_URL = data
      );
    }

  getSupplyItems() {
    return this.http.get<SupplyItem[]>(this.BASE_URL + '/buy/supplies/');
  }

  getSupplyItemsPages(
    page: string = '1',
    per_page: string = '10'
    )  {
      page = page.trim();
      per_page = per_page.trim();

      // Add safe, URL encoded search parameter if there is a search term
      const options = page ?
      { params: new HttpParams().set('page', page) } : {};

      return this.http.get<SupplyItem[]>(this.BASE_URL + '/buy/supplies/pages/', options);
  }

  getSupplyItem(item_id: number) {
    return this.http.get<SupplyItem>(this.BASE_URL + '/buy/supplies/' + item_id);
  }

  createSupplyItem(data: SupplyItem) {
    console.log('Service Payload', data);
    return this.http.post<SupplyItem>(this.BASE_URL + '/buy/supplies/', this.serialize(data));
  }

  updateSupplyItem( item_id: number, data: SupplyItem ) {
    console.log('Service Payload',item_id, data);
    return this.http.put<SupplyItem>(this.BASE_URL + '/buy/supplies/' + item_id, this.serialize(data));
  }

  deleteSupplyItem(item_id: number) {
    return this.http.delete(this.BASE_URL + '/buy/supplies/' + item_id);
  }

  serialize(SupplyItem: SupplyItem) {
    Object.keys(SupplyItem)
    .forEach(key => {
      if (SupplyItem[key] === "" || SupplyItem[key] === null || SupplyItem[key] === undefined) {
        delete SupplyItem[key];
      }
    });
    if (SupplyItem.notice_id) {
      SupplyItem.notice_id = Number(SupplyItem.notice_id);
    }
    if (SupplyItem.state) {
      SupplyItem.state = Boolean(SupplyItem.state);
    }
    return SupplyItem;
  }
}
