import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SuppliesService } from '../../../_services/supplies.service';
import { SupplyItem, createInitialSupplyItem } from '../../../_models/model-interfaces';
import { LocalService } from '../../../_services/local.service';

@Component({
  selector: 'app-supplyitem',
  templateUrl: './supplyitem.component.html',
  styleUrls: ['./supplyitem.component.scss']
})
export class SupplyitemComponent implements OnInit {

  supply$: SupplyItem = createInitialSupplyItem();
  itemid: number;

  constructor(
    private route: ActivatedRoute,
    private supplyService: SuppliesService,
    private data: LocalService) {
      this.fetchItem();
  }

  ngOnInit() {
    this.data.currentItem.subscribe(data => this.itemid = data);
  }

  fetchItem() {
    const id = +this.route.snapshot.paramMap.get('id');
    this.data.changeItemId(id);

    this.supplyService.getSupplyItem(id)
      .subscribe(
        data => {
          this.supply$ = data;
          console.log('Fetched supplyitem ', this.supply$);
        },
        error => {
          console.log('Error', error);
        }
    );
  }
}
