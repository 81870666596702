import { Component, OnInit, SimpleChanges, SimpleChange, HostListener } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { SuppliesService } from '../../_services/supplies.service';
import { SupplyItem } from '../../_models/model-interfaces';

@Component({
  selector: 'app-supplies',
  templateUrl: './supplies.component.html',
  styleUrls: ['./supplies.component.scss']
})
export class SuppliesComponent implements OnInit {

  supplies$: SupplyItem[];
  supplies_pages: any;
  page: string = '1';

  constructor(
    private supplyService: SuppliesService,
    private router: Router) {
  }

  ngOnInit() {
    this.fetchItems();
    this.fetchPages();
  }

  fetchItems() {
    this.supplyService.getSupplyItems()
      .subscribe(
        data => {
          console.log('Vorräte erfolgreich empfangen', this.supplies$);
          this.supplies$ = data;
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  updatePage(event) {
    console.log(this.page);
    this.fetchPages(this.page);
  }

  fetchPages(page: string = '1') {
    this.supplyService.getSupplyItemsPages(page)
    .subscribe(
      data => {
        console.log(data);
        this.supplies_pages = data;

      }
    );
  }

  delete(event): void {
    const id = event.target.name;
    console.log(id);

    this.supplyService.deleteSupplyItem(id)
      .subscribe(
        data => {
          console.log('Item erfolgreich entfernt', id);
          this.ngOnInit();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  @HostListener('window:keydown', ['$event'])
  onKeyDown(event: any) {
    console.log(event);
    if (event.key === "ArrowLeft") {
      this.backward();
    } else if ( event.key === "ArrowRight") {
      this.forward();
    }
  }

  backward() {
    let p = Number(this.page);
    if (p > 1) {
      this.page = String(p - 1);
      console.log('Backward',this.page);
      this.fetchPages(this.page);
    }
  }

  forward() {
    let p = Number(this.page);
    if (p < this.supplies_pages.pages) {
      this.page = String(p + 1);
      console.log('Forward',this.page);
      this.fetchPages(this.page);
    }
  }

  goBack(): void {
    this.router.navigate(['/notes']);
  }
}
