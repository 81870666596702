import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';

import { AuthGuard } from './_guards/auth.guard';

import { DashboardComponent } from './dashboard/dashboard.component';
import { RegisterComponent } from './register/register.component';
import { LoginComponent } from './login/login.component';
import { NotesComponent } from './shopping/notes/notes.component';
import { SuppliesComponent } from './shopping/supplies/supplies.component';
import { NoteComponent } from './shopping/notes/note/note.component';
import { NoteCreateComponent } from './shopping/notes/note-create/note-create.component';
import { NoteEditComponent } from './shopping/notes/note-edit/note-edit.component';
import { SupplyitemComponent } from './shopping/supplies/supplyitem/supplyitem.component';
import { SupplyitemCreateComponent } from './shopping/supplies/supplyitem-create/supplyitem-create.component';
import { SupplyitemEditComponent } from './shopping/supplies/supplyitem-edit/supplyitem-edit.component';


const routes: Routes = [
  {
    path: '',
    component: DashboardComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    component: RegisterComponent
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'settings',
    canActivate: [AuthGuard],
    loadChildren: './settings/settings.module#SettingsModule'
  },
  {
    path: 'notes/edit/:id',
    component: NoteEditComponent,
    canActivate: [AuthGuard]
  },  
  {
    path: 'notes/create',
    component: NoteCreateComponent,
    canActivate: [AuthGuard]
  },  
  {
    path: 'notes/:id',
    component: NoteComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'notes',
    component: NotesComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplyitem/edit/:id',
    component: SupplyitemEditComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplyitem/create',
    component: SupplyitemCreateComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplies/:id',
    component: SupplyitemComponent,
    canActivate: [AuthGuard]
  },
  {
    path: 'supplies',
    component: SuppliesComponent,
    canActivate: [AuthGuard]
  } 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
