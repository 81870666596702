import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { map, tap } from 'rxjs/operators';
import { JwtHelperService } from '@auth0/angular-jwt';
import { Observable, of, observable, Observer, BehaviorSubject } from 'rxjs';
import { ConfigService } from './config.service';

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  private BASE_URL;
  private loggedIn: BehaviorSubject<boolean>;

  constructor(private http: HttpClient,
    private conf: ConfigService) {
      this.conf.getAPI_URL().subscribe(
        data => this.BASE_URL = data
      );
      this.loggedIn = new BehaviorSubject<boolean>(false);
    }

  /*
   * Saves refreshed token into cookie
   */
  saveTokenInLocalStorage(token) {
    localStorage.setItem('access_token', JSON.stringify(token));
    this.decodeToken(token, 'access_token');
    console.log('Saved refreshed access_token:', JSON.parse(localStorage.getItem('access_token')));
  }

  /*
   * Returns token expiration timestamp (UTC in seconds)
   */
  getTokenExpiration(name: string) {
    return JSON.parse(localStorage.getItem(name + '_' + 'expires_at'));
  }


  /*
   * Returns true if token expired. Returns access_token by default.
   */
  isExpired(token_name: string = 'access_token') {
    const now = new Date();
    let utc: number;
    const token_expires = this.getTokenExpiration(token_name);

    utc = Number((now.valueOf() / 1000).toFixed(0));
    console.log('Now: ', utc, now);
    console.log('Token Expiration', token_name, token_expires);

    if ( utc > token_expires) {
      // if token is expired
      return true;
    } else {
      // if token is valid
      return false;
    }
  }


  // Observable login status
  isLoggedIn(): Observable<boolean> {
    if (!this.isExpired()) {
        this.loggedIn.next(true);
      } else {
        this.loggedIn.next(false);
      }
    return this.loggedIn;
  }

  /*
   *  Return the tokens from cookies
   */
  getCurrentAccessToken(Tokentype: string): Observable<string> {
    if ( Tokentype === 'access' ) {
      return JSON.parse(localStorage.getItem('access_token'));
    }
    if ( Tokentype === 'refresh' ) {
      return JSON.parse(localStorage.getItem('refresh_token'));
    }
  }

  /*
   * Refresh access_token with refresh_token if refresh_token is not
   * expired.
   */
  refreshToken() {

    const refreshToken = (this.getCurrentAccessToken('refresh'));
    console.log('Refresh token:', refreshToken);

    const httpOptions = {
      headers: new HttpHeaders({
        'X-API-KEY': `${refreshToken}`
      })};

    console.log('Headers', httpOptions);
    console.log(this.BASE_URL);
    return this.http.post<any>(this.BASE_URL + '/refresh', {}, httpOptions )
    .pipe(
      tap(
        data => {
          this.saveTokenInLocalStorage(data.access_token);
        },
        error => {
          console.log('Error', error);
        }
      ));
  }

  /*
   * User initial login function
   */
  login(email: string, password: string) {
    
    return this.http.post<any>(this.BASE_URL + '/login', { email: email, password: password })
    .pipe(
      map(
        user => {
          // login successful if there's a jwt token in the response
          if (user && user.access_token) {

              // store user details and jwt token in local storage to keep user logged in between page refreshes
              localStorage.setItem('access_token', JSON.stringify(user.access_token));
              localStorage.setItem('refresh_token', JSON.stringify(user.refresh_token));

              // fetch more info from tokens
              this.decodeToken(user.access_token, 'access_token');
              this.decodeToken(user.refresh_token, 'refresh_token');
          }
          return user;
        }));
  }

  /*
   * Fetch additional informations like expiration dates from the jwt token.
   */
  decodeToken(token: string, name: string) {
    const helper = new JwtHelperService();
    const decodedToken = helper.decodeToken(token);
    localStorage.setItem( name + '_' + 'expires_at', JSON.stringify(decodedToken.exp));
    console.log(name + '_' + 'expires_at', JSON.parse(localStorage.getItem(name + '_' + 'expires_at')));
  }

  /*
   * Deletes all token relevant informations from
   * localstorage.
   */
  logout() {
    localStorage.removeItem('access_token');
    localStorage.removeItem('access_token_expires_at');
    localStorage.removeItem('refresh_token');
    localStorage.removeItem('refresh_token_expires_at');
    this.isLoggedIn();
  }

  /*
   * Remove access_token from local storage
   */
  removeAccessToken() {
    localStorage.removeItem('access_token');
  }
}
