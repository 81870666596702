import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { HttpClient } from '@angular/common/http';


@Injectable({
  providedIn: 'root'
})
export class LocalService {

  private itemId = new BehaviorSubject<number>(0);
  currentItem = this.itemId.asObservable();


  constructor(
    private http: HttpClient) { }

  changeItemId(itemId: number) {
    // console.log('Item ID: ', itemId);
    this.itemId.next(itemId);
  }
}
