import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Note } from '../_models/model-interfaces';
import { ConfigService } from './config.service';


@Injectable({
  providedIn: 'root'
})
export class NotesService {

  BASE_URL;

  notes$: Observable<Note[]>;

  constructor(private http: HttpClient,
    private conf: ConfigService) { 
      this.conf.getAPI_URL().subscribe(
        data => this.BASE_URL = data
      );
    }

  getNotes(): Observable<Note[]> {
    console.log(this.BASE_URL);
    return this.http.get<Note[]>(this.BASE_URL + '/buy/memos/');
  }

  getNote(noteId: number): Observable<Note> {
    return this.http.get<Note>(this.BASE_URL + '/buy/memos/' + noteId);
  }

  createNote(note): Observable<Note> {
    return this.http.post<Note>(this.BASE_URL + '/buy/memos/', note);
  }

  updateNote(id, note): Observable<Note> {
    console.log('Das wird abgeschickt', note);
    return this.http.put<Note>(this.BASE_URL + '/buy/memos/' + id, note);
  }

  deleteNote(id): Observable<Note> {
    return this.http.delete<Note>(this.BASE_URL + '/buy/memos/' + id);
  }
}
