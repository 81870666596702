import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NotesService } from '../../../_services/notes.service';
import { Note, createInitialNote, SupplyItem, createInitialSupplyItem } from '../../../_models/model-interfaces';
import { SuppliesService } from '../../../_services/supplies.service';
import { LocalService } from '../../../_services/local.service';


@Component({
  selector: 'app-note',
  templateUrl: './note.component.html',
  styleUrls: ['./note.component.scss']
})
export class NoteComponent implements OnInit {

  note$: Note = createInitialNote();
  private supplies$: SupplyItem[];

  private supplyitem: any;

  name: string;
  note: string;

  private itemid: number;
  private currentItem: SupplyItem;

  private updateElement: number;
  private updateItem = true;
  private exist: Boolean = false;

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private notesService: NotesService,
    private supplyService: SuppliesService,
    private data: LocalService) {

    /*
    this.route.params
      .subscribe( params => this.note$ = params.id );
      */
  }

  ngOnInit() {
    this.name = '';
    this.note = '';
    this.fetchNote();
    this.fetchItems();
    this.data.currentItem.subscribe(itemid => this.itemid = itemid);
  }

  fetchNote(): void {
    const id = +this.route.snapshot.paramMap.get('id');
    this.data.changeItemId(id);
    this.notesService.getNote(id)
      .subscribe(
        data => {
          console.log('Successfully fetched note', this.note$);
          this.note$ = data;
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  fetchItems(): void {
    this.supplyService.getSupplyItems()
      .subscribe(
        data => {
          this.supplies$ = data;
          console.log('Successfully fetched supplies', this.supplies$);
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  cleanObject(obj: SupplyItem) {
    for ( const key in obj ) {
      if (obj[key] === null || obj[key] === undefined) {
        delete obj[key];
      }
    }
    return obj;
  }

  markAsDone(event) {
    const id = event.target.name;
    console.log('ID', id);

    this.getCurrentItem(id);

    if (this.currentItem.state === true) {
      this.currentItem.state = false;
    } else {
      this.currentItem.state = true;
    }
    this.updateSupplyItem(this.currentItem);
  }

  handleItem(formData) {

    let itemInDB = false;

    this.supplies$.forEach(element => {

      if (element.name === formData.name) {
        console.log('Item existiert schon');
        itemInDB = true;
        this.updateElement = element.id;
        this.supplyitem = element;
        this.supplyitem.amount = 1;
        this.supplyitem.state = false;
        this.supplyitem.notice_id = this.note$.id;
        if ( typeof formData.note !== 'undefined' ) {
          console.log('Notiz Feld gesetzt', formData);
          this.supplyitem.note = formData.note;
        } else {
          console.log('Notiz Feld NICHT gesetzt');
        }
        this.updateItem = true;
        return true;
      }
    });

    if ( itemInDB === false || this.supplies$.length === 0 ) {
      console.log('Item existiert nicht');
      const newItem: any = {
        name: formData.name,
        notice_id: this.note$.id,
        amount: 1
      };
      if ( typeof formData.note !== 'undefined' ) {
        console.log('Notiz Feld gesetzt');
        newItem.note = formData.note;
      } else {
        console.log('Notiz Feld NICHT gesetzt');
      }

      this.createSupplyItem(newItem);
      this.updateItem = false;
      return false;
    }
  }

  createSupplyItem(newItem: SupplyItem): void {
    this.updateItem = false;
    this.supplyService.createSupplyItem(newItem)
    .subscribe(
      data => {
        console.log('Item erfolgreich hinzugefügt');
        this.ngOnInit();
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  add(formData): void {
    console.log('Data: ', formData);
    console.log('Supplies: ', this.supplies$);

    this.exist = false;

    this.note$.supplies.forEach(element => {
      if (element.name === formData.name) {
        this.exist = true;
      }
    });
    if ( this.exist === false ) {
      this.handleItem(formData);
    } else {
      console.log('Item already on list.');
    }

    if (this.updateItem === true && this.exist !== true ) {
      console.log('Item wird geupdated');
      this.supplyService.updateSupplyItem(this.updateElement, this.cleanObject(this.supplyitem))
      .subscribe(
        data => {
          console.log('Vorrat hinzugefügt', this.supplyitem);
          this.ngOnInit();
        },
        error => {
          console.log('Error', error);
        }
      );
    }
  }

  getCurrentItem(id) {

    this.currentItem = createInitialSupplyItem();

    console.log('Searching for ID:',id);
    this.note$.supplies.forEach(element => {
      console.log('Searching for ID:', id, 'Current', element.id);
      if ( Number(element.id) === Number(id) ) {
        console.log('CurrentItem: ', this.currentItem);
        this.currentItem = element;
      }
    });
  }

  increase(event) {
    const id: number = event.target.name;

    this.getCurrentItem(id);

    this.currentItem.amount++;
    this.supplyService.updateSupplyItem(this.currentItem.id, this.cleanObject(this.currentItem))
    .subscribe(
      data => {
        console.log('Increased amount of ' + this.currentItem.name + ' to ' + this.currentItem.amount);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  decrease(event) {
    const id: number = event.target.name;

    this.getCurrentItem(id);
    if (this.currentItem.amount >= 1) {
      this.currentItem.amount--;
    }

    this.supplyService.updateSupplyItem(this.currentItem.id, this.cleanObject(this.currentItem))
    .subscribe(
      data => {
        console.log('Increased amount of ' + this.currentItem.name + ' to ' + this.currentItem.amount);
      },
      error => {
        console.log('Error', error);
      }
    );
  }

  removeItemFromList(event) {
    const id: number = event.target.name;
    this.getCurrentItem(id);

    /*
    this.note$.supplies = this.note$.supplies.filter(data => data.id !== Number(id));
    this.note$.supplies.forEach(element => {
      element = this.supplyService.serialize(element);
    });
    this.updateNote(this.note$.id, this.note$);
    */
    this.currentItem.notice_id = 0;
    this.updateSupplyItem(this.currentItem);
  }

  updateNote(note_id: number, payload: Note) {
    this.notesService.updateNote(note_id, payload)
      .subscribe(
        data => {
          this.note$ = payload;
          console.log('Note successfully updated', payload);
          this.ngOnInit();
        },
        error => {
          console.log('Error', error);
        }
      );
  }

  finishNote() {
    // hier muss noch geprüft werden ob alle Posten abgeschlossen sind
    this.note$.supplies
    .forEach(element => {
      element.stock = element.stock + element.amount;
      element.amount = 0;
      element.notice_id = 0;
      element = this.supplyService.serialize(element);
      this.supplyService.updateSupplyItem(element.id, element)
      .subscribe(
        data => {
          console.log('Item',element.id,'updated',element);
        }
      );
      this.note$.supplies = [];
      this.router.navigate(['/notes']);
    });
    
    
  }

  updateSupplyItem(item: SupplyItem) {
    console.log('Item wird geupdated');
    const payload = this.cleanObject(item);
    console.log(payload);
    this.supplyService.updateSupplyItem(item.id, payload)
    .subscribe(
      data => {
        console.log('Vorrat hinzugefügt', item);
        this.ngOnInit();
      },
      error => {
        console.log('Error', error);
      }
    );
  }
}
